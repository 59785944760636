import React, { useCallback, useContext, useState } from 'react';
import { Text, Theme, ThemeProvider, audiDarkTheme, responsiveStyles } from '@audi/audi-ui-react';
import styled from 'styled-components';
import { TrackingDataV2 } from '@oneaudi/audi-tracking-service';
import { getClassNamesForLinks, getLinkTarget } from '../../services';
import { timeHeaderTransitionDelay, timeS, timeXXL } from '../AudiHeader/AudiHeaderStyleUtils';
import { AudiHeaderTeaserProps } from '../../interfaces/header-components.interfaces';
import AudiHeaderTeaserTechLabel from './AudiHeaderTeaserTechLabel';
import { EventAction } from '../../utils/tracking';
import { TrackingServiceContext } from '../../context';
import { scriptRegex } from '../../utils/string-utils';

const HeaderStyledTeaserImageContainer = styled.div<{
  isImageLoaded: boolean;
  theme: Theme;
}>`
  margin-bottom: var(--one-header-space-xxxl);
  ${({ theme }): string =>
    responsiveStyles(
      {
        'max-width': {
          m: '432px',
        },
        width: {
          m: '50%',
          xl: '30vw',
        },
      },
      theme.breakpoints,
    )}
  overflow: hidden;
  width: 100%;
`;

const HeaderStyledTeaserImage = styled.img<{
  isImageLoaded: boolean;
}>`
  display: block;
  height: ${({ isImageLoaded }): string => (isImageLoaded ? 'auto' : '0')};
  ${({ isImageLoaded }): string => {
    if (isImageLoaded) {
      return `position: relative;
        width: 100%;`;
    }
    return '';
  }}
  opacity: 1;
  padding-bottom: ${({ isImageLoaded }): string => (isImageLoaded ? '0' : '56.25%')};
`;
/* eslint-disable */
const HeaderStyledTeaserImageShadow = styled.div<{ isImageLoaded: boolean }>`
  opacity: ${({ isImageLoaded }): string => (isImageLoaded ? '1' : '0')};
  transition: opacity var(${({ theme }): string => theme.easing}) ${timeHeaderTransitionDelay}
    ${timeS};
  z-index: 1;

  &::after {
    /* stylelint-disable */
    background-image: linear-gradient(to bottom, transparent, transparent 70%, #000 100%);
    /* stylelint-enable */
    bottom: 0;
    content: '';
    display: block;
    height: 100%;
    opacity: 0.7;
    position: absolute;
    width: 100%;
  }
`;
/* eslint-enable */
const HeaderStyledTeaserImageContainerInner = styled.div`
  overflow: hidden;
  position: relative;
`;

const HeaderStyledTeaserLink = styled.a<{
  isImageLoaded: boolean;
}>`
  align-items: flex-end;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  position: absolute;
  text-decoration: none;
  transform: ${({ isImageLoaded }): string =>
    isImageLoaded ? 'translateY(0)' : 'translateY(80px)'};
  transition: transform ${timeXXL} ${({ theme }): string => theme.easing} ${timeXXL};
  width: 100%;

  & span {
    flex-grow: 1;
  }

  & svg {
    color: var(--one-header-color-white);
    flex-shrink: 0;
    height: 48px;
    margin-bottom: var(--one-header-space-xxs);
    margin-right: var(--one-header-space-xxs);
    width: 48px;
  }
`;

const HeaderStyledTeaserWltpContainer = styled.div<{
  isImageLoaded: boolean;
}>`
  opacity: ${({ isImageLoaded }): string => (isImageLoaded ? '1' : '0')};
  padding-top: var(--one-header-space-s);
  transition: opacity ${({ theme }): string => theme.easing} ${timeXXL} ${timeS};
`;

const AudiHeaderTeaser: React.FC<AudiHeaderTeaserProps> = ({
  globalPosition,
  mainCategoryLabel,
  teaser,
  isSubNavOpen,
  useFootnoteReferenceServiceTextParserHook,
}) => {
  const fallbackImage =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR4nGP6zwAAAgcBApocMXEAAAAASUVORK5CYII=';

  const [isImageLoaded, setImageLoaded] = useState<boolean>(false);
  const [imageSource, setImageSource] = useState<string>(teaser.Image.Src);

  const teaserImageLoaded = useCallback((): void => {
    if (imageSource !== fallbackImage && isSubNavOpen) {
      setImageLoaded(true);
    } else {
      setImageSource(teaser.Image.Src);
    }
  }, [isSubNavOpen, imageSource, teaser.Image.Src]);

  const dangerousHTML = useFootnoteReferenceServiceTextParserHook(
    teaser.Link.Text.replace(scriptRegex, ' illegal script '),
  );

  const showTeaserWltpContainer =
    !!teaser && !!teaser.Wltp && !!teaser.Wltp.length && teaser.Wltp.length > 0;

  const { trackingService } = useContext(TrackingServiceContext);

  const trackOnTeaserClick = (e: React.MouseEvent) => {
    e.preventDefault();
    const teaserClickEvent: TrackingDataV2 = {
      event: {
        eventInfo: {
          eventAction: EventAction.INTERNAL,
          eventName: 'one header - teaser click',
        },
        attributes: {
          componentName: EventAction.COMPONENT_NAME,
          label: `Menu > ${mainCategoryLabel} > ${dangerousHTML}`,
          targetURL: teaser.Link.Url,
          clickID: '',
          elementName: 'image',
          value: '',
          pos: globalPosition,
        },
      },
    };
    trackingService?.track(teaserClickEvent);
    window.open(teaser.Link.Url, '_blank');
  };

  return (
    <HeaderStyledTeaserImageContainer isImageLoaded={isImageLoaded}>
      <HeaderStyledTeaserImageContainerInner>
        <HeaderStyledTeaserImageShadow isImageLoaded={isImageLoaded}>
          <HeaderStyledTeaserImage
            alt={teaser.Image.Alt}
            height={teaser.Image.Height}
            isImageLoaded={isImageLoaded}
            onLoad={teaserImageLoaded}
            {...(isSubNavOpen || isImageLoaded ? { src: imageSource } : { src: fallbackImage })}
            width={teaser.Image.Width}
          />
        </HeaderStyledTeaserImageShadow>
        <HeaderStyledTeaserLink
          tabIndex={isSubNavOpen ? 0 : -1}
          className={getClassNamesForLinks(teaser.Link)}
          href={teaser.Link.Url}
          isImageLoaded={isImageLoaded}
          target={getLinkTarget(teaser.Link)}
          onClick={trackOnTeaserClick}
        >
          <ThemeProvider theme={audiDarkTheme}>
            <Text
              as="span"
              spaceInlineEnd="m"
              spaceInlineStart="m"
              spaceStackEnd="m"
              variant="order4"
            >
              <span
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: dangerousHTML,
                }}
              />
            </Text>
          </ThemeProvider>
          <svg height="48" viewBox="0 0 48 48" width="48" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.4 32.6L28.5 23.5 19.4 14.4" fill="none" stroke="currentColor" />
          </svg>
        </HeaderStyledTeaserLink>
      </HeaderStyledTeaserImageContainerInner>
      {showTeaserWltpContainer && (
        <HeaderStyledTeaserWltpContainer isImageLoaded={isImageLoaded}>
          {teaser.Wltp?.map((Wltp) => {
            const key = `${Wltp.TechLabel}${Wltp.TechValue}`;
            const techLabelValue = `${Wltp.TechLabel} ${Wltp.TechValue}`;
            return (
              <AudiHeaderTeaserTechLabel
                key={key}
                techLabelValue={techLabelValue}
                useFootnoteReferenceServiceTextParserHook={
                  useFootnoteReferenceServiceTextParserHook
                }
              />
            );
          })}
        </HeaderStyledTeaserWltpContainer>
      )}
    </HeaderStyledTeaserImageContainer>
  );
};

export default AudiHeaderTeaser;
